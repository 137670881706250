import React from 'react';
import { Typography } from '@mui/material';

import { TextProps } from './Text.props';

export function Text({ text, ...rest }: TextProps) {
  return (
    <Typography paragraph align="center" {...rest}>
      {text}
    </Typography>
  );
}

export function SideInfoText({ text, ...rest }: TextProps) {
  return (
    <Typography
      paragraph
      align="center"
      variant="body2"
      color="InactiveCaptionText"
      {...rest}
    >
      {text}
    </Typography>
  );
}
