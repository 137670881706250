import React from 'react';
import { navigate } from 'gatsby';
import { Box, Container, Typography, Link, Button } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../firebase';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright ©
      <Link color="inherit" href="https://sweal.me/">
        sweal.me
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function Footer() {
  const [user] = useAuthState(auth);

  return (
    <footer>
      <Box mt={4} p={4}>
        {user ? (
          <Button fullWidth onClick={() => logout()}>
            Abmelden
          </Button>
        ) : (
          <Button fullWidth onClick={() => navigate('/login')}>
            Login
          </Button>
        )}
      </Box>
      <Box mt={4} p={4}>
        <Container maxWidth="sm">
          <Typography variant="h6" align="center" gutterBottom>
            {' '}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            {' '}
          </Typography>
          <Copyright />
        </Container>
      </Box>
    </footer>
  );
}
