import React from 'react';
import { Box } from '@mui/material';
import { Text } from '../index';
export interface HeaderProps {
  title: string;
  subtitle?: string;
}

export function Header({ title, subtitle }: HeaderProps) {
  return (
    <Box pt={4} pl={4} pr={4}>
      <header>
        <Text.Titles.Title text={title} />
        {subtitle && <Text.Titles.SubTitle text={subtitle} />}
      </header>
    </Box>
  );
}
