import React from 'react';
import { IconProps } from '@mdi/react/dist/IconProps';
import { Icon as MDIReactIcon } from '@mdi/react';
import * as MDIIcons from '@mdi/js';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  buttonRoot: { '& > *': { margin: theme.spacing(1) } },
  contrast: { color: theme.palette.primary.contrastText },
  error: { color: theme.palette.error.main },
  warning: { color: theme.palette.warning.main },
  success: { color: theme.palette.success.main },
  primary: { color: theme.palette.primary.main },
  secondary: { color: theme.palette.secondary.main },
}));

interface StyledIconProps extends IconProps {
  iconName: string;
  color?: string | 'contrast';
  path?: never;
}

export function Icon({ color, iconName, ...rest }: StyledIconProps) {
  const classes = useStyles();
  const iconClass = classes[color];
  return (
    <MDIReactIcon
      className={iconClass || ''}
      path={MDIIcons[iconName]}
      {...rest}
    />
  );
}
