import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  GatsbyImageProps,
} from 'gatsby-plugin-image';
import { Box } from '@mui/material';
import { ParticleComp as Particles } from '../Particles';

const useStyles = makeStyles(() => ({
  imageRootContainer: { height: '100%' },
  effectContainer: { position: 'relative', height: '100%' },
  imageEffect: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  image: { height: '100%' },
}));

export interface ImageWithParticlesProps extends GatsbyImageProps {
  imageData: ImageDataLike;
  image?: never;
}

export function ImageWithParticles({
  imageData,
  ...rest
}: ImageWithParticlesProps) {
  const classes = useStyles();
  const image = getImage(imageData);
  return (
    <Box className={classes.imageRootContainer}>
      <div className={classes.effectContainer}>
        <div className={classes.imageEffect}>
          <Particles />
        </div>
        <GatsbyImage image={image} {...rest} />
      </div>
    </Box>
  );
}
