import { initializeApp } from 'firebase/app';

const _firebaseConfig = {
  apiKey: 'AIzaSyDV1sm6STuoQXurXV6dtt2wZj_20kjQwoQ',
  authDomain: 'swipe-your-meal.firebaseapp.com',
  projectId: 'swipe-your-meal',
  storageBucket: 'swipe-your-meal.appspot.com',
  messagingSenderId: '444755588509',
  appId: '1:444755588509:web:00254e3c9e73654900eb74',
  measurementId: 'G-0WE9Q8VYNX',
};

export const firebaseApp = initializeApp(_firebaseConfig);
