import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from 'firebase/auth';
import { firebaseApp } from './app';

export const auth = getAuth(firebaseApp);
export const logout = () => signOut(auth);

export const doGoogleLogin = async () =>
  signInWithPopup(auth, new GoogleAuthProvider());
