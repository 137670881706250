import React from 'react';
import Particles from 'react-tsparticles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  canvas: {
    height: `100% !important`,
    width: `100% !important`,
    position: 'absolute !important' as any,
    zIndex: '5 !important' as any,
  },
  canvasContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    zIndex: 5,
  },
}));

export function ParticleComp() {
  const classes = useStyles();
  return (
    <div className={classes.canvasContainer}>
      <Particles
        id="swewal-particles"
        style={{ backgroundColor: 'red' }}
        canvasClassName={classes.canvas}
        options={{
          fpsLimit: 60,
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 500,
              },
            },
            color: { value: '#ffffff' },
            shape: {
              type: 'triangle',
              stroke: {
                width: 0,
                color: '#000000',
              },
              polygon: { nb_sides: 3 },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: true,
                speed: 10,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 100,
              color: '#ffffff',
              opacity: 0.75,
              width: 0.5,
            },
            move: {
              enable: true,
              speed: 1,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'bounce',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'grab',
              },
              onclick: {
                enable: false,
                mode: 'push',
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 100,
                line_linked: { opacity: 1 },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: { particles_nb: 4 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  );
}
