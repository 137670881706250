import React from 'react';
import { Typography } from '@mui/material';
import { TextProps } from './Text.props';

export function Title({ text, ...rest }: TextProps) {
  return (
    <Typography
      component="h1"
      variant="h2"
      align="center"
      color="primary"
      gutterBottom
      {...rest}
    >
      {text}
    </Typography>
  );
}

export function SubTitle({ text, ...rest }: TextProps) {
  return (
    <Typography
      variant="h5"
      align="center"
      color="textSecondary"
      paragraph
      {...rest}
    >
      {text}
    </Typography>
  );
}
