import React from 'react';
import { Grid, Box, Paper, Container, Divider } from '@mui/material';
import { Footer, ImageWithParticles, Header } from '../components';
import { ImageWithParticlesProps } from '../components/Image-With-Particles';
import { HeaderProps } from '../components/Header';
interface StandardLayoutProps {
  children: React.ReactNode;
  imageProps: ImageWithParticlesProps;
  headerProps: HeaderProps;
}

export function StandardLayout({
  children,
  imageProps,
  headerProps,
}: StandardLayoutProps): JSX.Element {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Box>
          <ImageWithParticles
            {...imageProps}
            style={{ height: '75vh', width: '100%' }}
          ></ImageWithParticles>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="md" disableGutters>
          <Paper
            elevation={24}
            style={{ marginTop: '-15vh', position: 'relative', zIndex: 99 }}
          >
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Header {...headerProps}></Header>
                  <Divider></Divider>
                  <main>{children}</main>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                  <Footer></Footer>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
}
