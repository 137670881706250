import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';

export const PrivateRoutes = ({
  component: Component,
  location = null,
  ...rest
}) => {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading === false && user == null && location?.pathname !== `/login`) {
      navigate('/login');
      return null;
    }
  }, [loading, user, location]);

  return <Component {...rest} />;
};

export const DefaultPrivateRoute = ({ location = null, ...rest }) => {
  if (location?.pathname !== `/404`) {
    navigate('/404');
  }
  return <div />;
};
